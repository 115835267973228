
//  default
[data-theme-mode="default"] {
    --bs-danger: #ef6767 !important;
    --bs-danger-rgb: 239,103,103 !important;
    
    --bs-success: #34c38f !important;
    --bs-success-rgb: 52,195,143 !important;

    --bs-primary: #1c84ee !important;
    --bs-primary-rgb: 28, 132, 238 !important;
}


// Red
[data-theme-mode="red"] {
    --bs-primary: #ff7784 !important;
    --bs-primary-rgb: 255, 119, 132 !important;
    
    --bs-danger: #34c38f !important;
    --bs-danger-rgb: 255,119,132 !important;

    --bs-success: #23d7e1 !important;
    --bs-success-rgb: 52,195,143 !important;
}


// purple
[data-theme-mode="purple"] {
    --bs-primary: #6951ce !important;
    --bs-primary-rgb: 105, 81, 206 !important;

    --bs-danger: #34c38f !important;
    --bs-danger-rgb: 239, 103, 103 !important;

    --bs-success: #f04d80 !important;
    --bs-success-rgb: 52, 195, 143 !important;
}
